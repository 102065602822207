import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import BeckySmithVA from "../Images/BeckySmithVALogo.webp";
import TheGiftWorld from "../Images/TheGiftWorldScreenshot.webp";
import NewbieTeacher from "../Images/NewbieTeacherLogo.webp";
import JoshDarby from "../Images/JoshDarbySiteScreenshot.webp";

export const PortfolioSection = () => {
  return (
    <Container
      fluid
      data-aos="fade-up"
      as="section"
      className="portfolioSection"
      id="portfolio"
    >
      <Row>
        <Col>
          <h2>
            view My <span>Latest Work</span>
          </h2>
          <p className="text-center mt-5">
            Here are a few of my most recent projects, I'm always open to
            working with different clients and technologies.
            <br /> I enjoy variety and a challenge, so if you are looking to
            start something new then get in touch and we can create something
            unique together!
          </p>
        </Col>
      </Row>

      <Row className="portfolioRowOne">
        <Col data-aos="fade-right">
          <a href="/josh-darby-website-showcase">
            <Image
              src={JoshDarby}
              width="500"
              height="350"
              alt="Becky Smith Website Logo"
              className="image"
            />
            <div className="overlayOne">
              <div className="text">
                <br />
                <br />
                Josh Darby Drummer
                <p>London Based Sessions Drummer</p>
              </div>
            </div>
            <div className="overlayTwo">
              <div className="text">
                <Button variant="third" size="lg">
                  Find Out More
                </Button>
              </div>
            </div>
          </a>
        </Col>

        <Col data-aos="fade-down">
          <a href="/becky-smith-virtual-assistant-showcase">
            <Image
              src={BeckySmithVA}
              width="500"
              height="350"
              alt="Becky Smith Website Logo"
              className="image"
            />
            <div className="overlayOne">
              <div className="text">
                <br />
                <br />
                BSVA
                <p>London Based Personal Assistant</p>
              </div>
            </div>
            <div className="overlayTwo">
              <div className="text">
                <Button variant="third" size="lg">
                  Find Out More
                </Button>
              </div>
            </div>
          </a>
        </Col>

        <Col data-aos="fade-down">
          <a href="/chocolate-indulgence-website-showcase">
            <Image
              src={TheGiftWorld}
              width="500"
              height="350"
              alt="Chocolate Indulgence Website Screenshot"
              className="image"
            />
            <div className="overlayOne">
              <div className="text">
                <br />
                <br />
                Chocolate Indulgence
                <p>Online handcrafted chocolate shop based in Cambridgeshire</p>
              </div>
            </div>
            <div className="overlayTwo">
              <div className="text">
                <Button variant="third" size="lg">
                  Find Out More
                </Button>
              </div>
            </div>
          </a>
        </Col>

        <Col data-aos="fade-left">
          <a href="/teacher-newbie-website-showcase">
            <Image
              src={NewbieTeacher}
              width="500"
              height="350"
              alt="Teacher Newbie Website Screenshot"
              className="image"
            />
            <div className="overlayOne">
              <div className="text">
                <br />
                <br />
                Teacher Newbie
                <p>US Based Education Website</p>
              </div>
            </div>
            <div className="overlayTwo">
              <div className="text">
                <Button variant="third" size="lg">
                  Find Out More
                </Button>
              </div>
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  );
};
