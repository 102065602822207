import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SEO = (props) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{props.title}</title>
        {props.noIndex === true ? (
          <meta name="robots" content="noindex" />
        ) : (
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />
        )}
        <meta name="twitter:title" content={props.title} />
        <meta property="og:title" content={props.title} />
        <meta property="og:site_name" content={props.title} />
        <meta name="description" content={props.description} />
        <meta property="og:description" content={props.description} />
        <meta name="image" property="og:image" content={props.image} />
        <meta property="og:image:alt" content={props.imageDescription} />
        <meta property="og:image:width" content={props.imageWidth} />
        <meta property="og:image:height" content={props.imageHeight} />
        <meta name="twitter:image" content={props.image} />
        <meta property="og:url" content={props.url} />
      </Helmet>
    </HelmetProvider>
  );
};

export default SEO;
