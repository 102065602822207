import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import {
  faCss3Alt,
  faHtml5,
  faJs,
  faPython,
  faReact,
  faWordpress,
} from "@fortawesome/free-brands-svg-icons";

const ampersand = "&";

export const WhatIDoSection = () => {
  return (
    <Container
      data-aos="fade-up"
      className="whatIDoSection"
      id="whatIDo"
      as="section"
    >
      <Row>
        <Col>
          <h2>What I do</h2>
          <h3>
            Software Development, Web Development <br />
            <span>Web Design, SEO {ampersand} Copywriting</span>
          </h3>
        </Col>
      </Row>

      <Row className="listOne">
        <Col>
          <ul className=" text-center">
            <li className="list-inline-item fw-bold">
              <span>&#9830;&nbsp;</span> E-commerce Sites
            </li>
          </ul>

          <ul className="list-inline text-center">
            <li className="list-inline-item fw-bold">
              <span>&#9830;&nbsp;</span> Personal Blogs
            </li>
          </ul>

          <ul className="list-inline text-center">
            <li className="list-inline-item fw-bold">
              <span>&#9830;&nbsp;</span> Businesses and Charities
            </li>
          </ul>
          <ul className="list-inline text-center">
            <li className="list-inline-item fw-bold">
              <span>&#9830;&nbsp;</span> React Web Apps
            </li>
          </ul>

          <ul className="list-inline text-center">
            <li className="list-inline-item fw-bold">
              <span>&#9830;&nbsp;</span> WordPress Sites
            </li>
          </ul>

          <ul className="list-inline text-center">
            <li className="list-inline-item fw-bold">
              <span>&#9830;&nbsp;</span> C# {ampersand} Python Projects
            </li>
          </ul>
        </Col>
      </Row>

      <Row id="blurb">
        <Col>
          <p className="mt-5 mb-5">
            I am proficient in React (this very site is built in React in fact),
            I have a great amount of experience with WordPress and am also able
            to code in C#, Java, Python and ASP.Net too. My skills are not just
            limited to coding either as I also have experience with writing
            copy, refining and optimising SEO and design work as well.
          </p>
        </Col>
      </Row>

      <Row id="technologies">
        <Col className="mt-5 d-flex justify-content-evenly align-items-center">
          <FontAwesomeIcon icon={faReact} size="6x" spin />
          <FontAwesomeIcon icon={faWordpress} size="6x" />
          <FontAwesomeIcon icon={faCss3Alt} size="6x" />
          <FontAwesomeIcon icon={faHtml5} size="6x" />
          <FontAwesomeIcon icon={faJs} size="6x" />
          <FontAwesomeIcon icon={faPython} size="6x" spin />
        </Col>
      </Row>
    </Container>
  );
};
