import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SEO from "../Components/SEO";
import SEOImage from "../Images/WebsitesForSale.webp";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

export const PagesForSale = () => {
  return (
    <>
      <Navbar />
      <main>
        <Container fluid className="siteOffers" as="section">
          <SEO
            title="Pre-Built Websites | Bobby McGrath - Freelance Web Developer"
            description="Hi, I'm Bobby McGrath, a web developer based in Peterborough, UK. This page provides information on pre-built websites I have for sale."
            image={`https://www.bobby-mcgrath.co.uk${SEOImage}`}
            imageDescription="Screenshot of the pre-built websites for sale page."
            imageWidth="1200"
            imageHeight="630"
            url="https://www.bobby-mcgrath.co.uk/pre-built-websites"
          />
          <Row>
            <Col>
              <h1 data-aos="fade-left">
                Pre-Built <span>Websites For Sale</span>
              </h1>
              <p
                data-aos="fade-right"
                className="text-center mt-5 w-50 introText"
              >
                I am currently offering websites which are fully functional and
                available for sale. <br />
                Each website on offer can be modified to include your own text,
                images and the website URL can also be modified to a website
                name of your choice (if it isn't already taken of course!). Feel
                free to browse the current pre-built websites on offer, if you
                are interested in purchasing one feel free to{" "}
                <a
                  className="contactFormLink"
                  href="/contact"
                  aria-label="Contact form link"
                >
                  get in touch.
                </a>
              </p>
            </Col>
          </Row>
        </Container>

        <hr className="siteSpacer" />

        <Container className="websiteSales" data-aos="fade-left" as="section">
          <Row className="websitesOnOffer">
            <Col sm="12" md="12" className="websiteDescription">
              <h2>E-commerce Website</h2>
              <p>
                This is a website which would suit an online store looking to
                sell their products to customers. It has PayPal integration, can
                accept cash payments, contact forms for potential customers to
                get in contact and looks great on both mobile and desktop
                devices. This website would suit a wide variety of scenarios and
                is suitable for you if you are looking to sell products online.
              </p>
              <a
                href="https://business-site-demo.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="mb-5" variant="third">
                  Click Here to View the Site
                </Button>
              </a>
            </Col>
          </Row>
        </Container>

        <hr className="siteSpacer" />

        <Container className="websiteSales" data-aos="fade-left" as="section">
          <Row className="websitesOnOffer">
            <Col sm="12" md="12" className="websiteDescription">
              <h2>Individual Website</h2>
              <p>
                This website would be perfect for someone who needs a website as
                an individual. There are many potential uses for this site, for
                instance, it can act as an online CV, a way to showcase your
                works if you are a photographer/musician or can even act as a
                website for a small sole trader type business. If this describes
                who you are and what you are looking to achieve from your
                website, get in touch today!
              </p>
              <a
                href="https://business-site-three-demo.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="mb-5" variant="third">
                  Click Here to View the Site
                </Button>
              </a>
            </Col>
          </Row>
        </Container>

        <hr className="siteSpacer" />
      </main>
      <Footer />
    </>
  );
};
