import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";

export const FrequentlyAskedQuestions = () => {
  return (
    <Container
      fluid
      data-aos="fade-up"
      className="faqSection"
      id="frequentlyAskedQuestions"
      as="section"
    >
      <Row>
        <Col>
          <h2 className="text-center text-white">Frequently Asked Questions</h2>
        </Col>
      </Row>
      <Row>
        <Accordion>
          <Accordion.Item data-aos="fade-left" eventKey="0">
            <Accordion.Header>How do I get my Website online?</Accordion.Header>
            <Accordion.Body>
              Before we commence with a project it is important that you have
              two things:
              <ul>
                <li>A hosting provider</li>
                <li>And a domain name</li>
              </ul>
              With these two things you can get your website online, I would
              also recommend buying these two things from the same provider if
              possible to keep things seamless. With these two elements I can
              help get your site online. If you are looking for a WordPress site
              I would recommend{" "}
              <a
                rel="noreferrer nofollow"
                href="https://www.bluehost.com/"
                id="bluehostRecommendation"
                target="_blank"
              >
                bluehost
              </a>
              , I have used them myself and for a basic package it can be very
              affordable. You also receive a free domain for a year too which is
              always useful!
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item data-aos="fade-left" eventKey="1">
            /<Accordion.Header>How much does a website cost?</Accordion.Header>
            <Accordion.Body>
              This will depend on the size and complexity of the site, for
              instance one person may need a business level site with several
              pages, contact forms and e-commerce functionality whilst another
              may simply need a single page site to advertise themselves and
              their services, so the pricing for these two requests would be
              different as the time it would take, and the scope of these
              requests would differ greatly from one another.
              <br />
              <br />
              The best way to find out how much your request would cost would be
              to contact me (my contact email address which links directly to a
              mailer service can be found below).
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item data-aos="fade-right" eventKey="2">
            /
            <Accordion.Header>
              How long does a website take to build?
            </Accordion.Header>
            <Accordion.Body>
              This ties in neatly with the previous question, this will also
              depend on the complexity of the project. For most projects I
              require between 4-6 weeks which includes getting the site set up
              responsively to work on multiple device types, benchmarking SEO,
              including copy (if required) and optimising speed and images.
              <br />
              <br />
              For larger scale projects my timescale will increase to 8-12 weeks
              as I will of course need to include more things in the site
              alongside the aforementioned services I provide.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </Container>
  );
};
