import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import SEO from "../Images/SEO.webp";
import Responsiveness from "../Images/Responsiveness.webp";

export const SEOSection = () => {
  return (
    <Container data-aos="fade-up" className="SEOSection" id="SEO" as="section">
      <Row className="mt-5 mb-5">
        <Col className="text-center">
          <h2 className="display-2 h2 pb-5 pt-5">
            Real Testing<span> For Better Results</span>
          </h2>
          <p>
            Each site I make goes through rigorous testing to ensure they pass
            the audits created by Google themselves.
            <br />
            As you can see in the image of the Google Lighthouse score for my
            site below, it has been optimised in many different areas. This is
            particularly important when trying to improve SEO as besides the
            standard practices that must be followed to ensure a better chance
            of ranking higher, so should site accessibility, best practice and
            performance scores too as when Google indexes a site to determine
            where it will appear it will also be looking at these factors as
            well.
            <br />
            <br />I must point out however that getting a number 1 ranking on
            Google is never guaranteed and if any one claims they can do this
            for you, they are being dishonest. That being said, with my focused
            and dedicated approach to testing you will certainly have a better
            chance of appearing higher up and in the process you will be giving
            your users an improved experience and increasing the likelihood of
            retention too!
          </p>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mt-5 mb-5 d-flex justify-content-evenly align-items-center">
          <figure>
            <Image
              className="lighthouse-score shadow"
              src={SEO}
              alt="Google Lighthouse Score for Bobby McGrath Portfolio Website"
              data-aos="fade-left"
            />
            <figcaption className="text-center pt-5">
              Google Lighthouse Score for my Website
            </figcaption>
          </figure>
        </Col>
      </Row>

      <Row className="mb-5" data-aos="fade-in">
        <Col className="text-center">
          <hr className=" mb-5" />
          <p className="pt-3">
            I also ensure that every site I make looks great on any device, with
            more and more people using their mobiles its important to ensure
            that user experience on a smaller device is just as enjoyable and
            rewarding as it would be on a PC or laptop, for this reason I always
            strive to ensure that my sites are fully responsive.
          </p>
        </Col>
      </Row>

      <Row className="mt-5 mb-5">
        <Col className="mt-5 mb-5 d-flex justify-content-evenly align-items-center">
          <figure>
            <Image
              className="responsiveness shadow"
              src={Responsiveness}
              alt="Image demonstrating site responsiveness on mobile and desktop screens for this site"
              data-aos="fade-right"
            />
            <figcaption className="text-center pt-5">
              I always test responsiveness using{" "}
              <a
                rel="noreferrer"
                href="https://www.lambdatest.com/"
                target="_blank"
              >
                LambdaTest
              </a>{" "}
              for each of my sites too.
            </figcaption>
          </figure>
        </Col>
      </Row>
    </Container>
  );
};
