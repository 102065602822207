import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import TeacherNewbieScreenshot from "../Images/TeacherNewbieScreenShot.webp";
import CustomerReviewScreenshot from "../Images/CustomerReviewScreenshot.webp";
import CustomThemeScreenshot from "../Images/CustomThemeScreenshot.webp";
import Disclaimer from "./Disclaimer";
import SEO from "../Components/SEO";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

export const TeacherNewbieSiteInfo = () => {
  const disclaimerText = `Please note that the domain wasn't renewed for this site and I was unable to contact the owner to get permission to host the site for demo purposes, as such this site cannot be viewed here. 
  <br/><br/>
  However, there is still plenty of information above which discusses the project and how it was originally built, as well as previous projects like <a href="https://www.bobby-mcgrath.co.uk/josh-darby-website-showcase">this one</a> where you can see a demo version of my work.`;

  return (
    <>
      <Navbar />
      <Container fluid as="main" className="site-showcase">
        <SEO
          title="Teacher Newbie Website | Bobby McGrath - Freelance Web Developer"
          description="Hi, I'm Bobby McGrath, a web developer based in Peterborough, UK. This page provides information on a website I built for a Texas, USA based online teaching company called Teacher Newbie."
          image={`https://www.bobby-mcgrath.co.uk${TeacherNewbieScreenshot}`}
          imageDescription="Screenshot of the Teacher Newbie homepage."
          imageWidth="1200"
          imageHeight="630"
          url="https://www.bobby-mcgrath.co.uk/teacher-newbie-website-showcase"
        />
        <Row data-aos="fade-in" as="section">
          <Col>
            <h1>
              <span>Teacher Newbie</span> Case Study
            </h1>
          </Col>
        </Row>
        <Row data-aos="fade-right" as="section">
          <Col>
            <p className="project-description">
              Teacher Newbie was my first project for a non-UK based client and
              was created for a Texas based educational startup company which
              offers lesson plans and educational resources to new teachers. It
              uses a subscription based model where sample lesson plans are
              provided to site visitors as a way to encourage them to subscribe
              and get full access to the content which is updated on a weekly
              basis.
              <br />
              <br />
              This was a commission I got as a result of{" "}
              <a
                href="https://www.fiverr.com/mcgrathb89/create-a-wordpress-website-for-you"
                target="_blank"
                rel="noreferrer"
              >
                my Fiverr WordPress job advert
              </a>{" "}
              which offered my freelance services to people looking for a custom
              built WordPress website. This job was accepted and agreed upon by
              myself and the client to be completed throughout the Christmas
              period (in fact I finished it and handed it to the client on
              Christmas day which was a nice present for them!) it took me 3
              days to complete from start to finish and was completed using
              designs which were provided by the client themselves.
              <br />
              <br />
              After the build was completed the site was passed over to the
              client for them to add their own images and copy and is currently
              under construction, it is still available to view online whilst it
              is being built thanks to the inclusion of a store notice which
              lets users know that the site is still being built whilst allowing
              them to access the sample lesson plans as a way to drum up
              interest prior to the full launch and subsequent advertisement
              campaign. I also included SEO (using
              <a href="https://yoast.com/" target="_blank" rel="noreferrer">
                {" "}
                Yoast SEO
              </a>
              ) as well as writing some of the copy for the website myself to
              help them hit the ground running on their new venture.
              <br />
              <br />
              This site itself is built in WordPress using{" "}
              <a href="https://elementor.com/" target="_blank" rel="noreferrer">
                Elementor
              </a>{" "}
              and utilises a newsletter signup system using the{" "}
              <a
                href="https://contactform7.com/"
                target="_blank"
                rel="noreferrer"
              >
                Contact Form 7 plugin
              </a>{" "}
              (which is protected from spam using an invisible Google reCaptacha
              system), it also uses{" "}
              <a
                href="https://woocommerce.com/products/woocommerce-subscriptions/"
                target="_blank"
                rel="noreferrer"
              >
                WooCommerce Subscriptions
              </a>{" "}
              for the payment gateway and it includes the same logic used in the{" "}
              <a href="https://aimlearn.org/" target="_blank" rel="noreferrer">
                AimLearn website
              </a>{" "}
              which prevents non-subscribers from accessing subscription based
              content.
              <br />
              <br />
              The theme was custom made specifically for this project which was
              styled with CSS, throughout the project I provided the client with
              video updates to keep them informed on the progress of the project
              and also gave them a tutorial video at the end of the project
              which gave them instructions on how to update and add new content
              to their site. Upon completion of the project I was given a
              glowing review which has helped me build a reputation outside of
              the UK freelancer market.
            </p>
          </Col>
        </Row>
        <Row data-aos="fade-left" as="section">
          <Col>
            <figure>
              <Image
                className="mt-5 shadow"
                fluid
                src={TeacherNewbieScreenshot}
                alt="Screenshot of Teacher Newbie Website"
              />
              <figcaption>Screenshot of the Teacher Newbie website</figcaption>
            </figure>
          </Col>
        </Row>
        <Row data-aos="fade-right" className="mt-5" as="section">
          <Col>
            <figure>
              <Image
                className="mt-5 shadow"
                fluid
                src={CustomerReviewScreenshot}
                alt="Screenshot of customer review"
              />
              <figcaption>
                Review I got from the client upon completion of the site
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row data-aos="fade-left" className="mt-5" as="section">
          <Col>
            <figure>
              <Image
                className="mt-5 shadow"
                fluid
                src={CustomThemeScreenshot}
                alt="Screenshot demonstrating that the theme for the website was custom built"
              />
              <figcaption>
                The theme for this project was custom built by me in three days
                during the Christmas period
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row data-aos="fade-in" as="section">
          <Col>
            <Disclaimer text={disclaimerText} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
