import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../Images/FooterLogo.png";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Image } from "react-bootstrap";

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  var weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";
  var day = weekday[date.getDay()];

  return (
    <footer className="text-center text-lg-start siteFooter text-white pt-5">
      <section>
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <section className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <a href="/">
                <Image
                  src={Logo}
                  alt="Footer Logo - Image of a person sitting at a keyboard with the words Bobby McGrath next to it."
                  className="footerLogo"
                />
              </a>
              <p className="text-justify mt-3 footer-text">
                Thanks for stopping by this {day} to see my portfolio, I truly
                appreciate that you have taken the time to view my work!
              </p>
            </section>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"></div>
            <section className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h2 className="text-uppercase fw-bold mb-4 footer-title">
                Footer Menu
              </h2>
              <nav>
                <ul className="list-unstyled fs-6">
                  <li className="pb-3">
                    <a href="/" className="text-reset">
                      Home
                    </a>
                  </li>
                  <li className="pb-3">
                    <a href="/pre-built-websites" className="text-reset">
                      Websites for Sale
                    </a>
                  </li>
                  <li className="pb-3">
                    <a href="/contact" className="text-reset">
                      Contact
                    </a>
                  </li>
                  <li className="pb-3">
                    <a
                      rel="nofollow"
                      href="/cookies"
                      className="text-reset"
                      aria-label="Read the site cookies policy"
                    >
                      Cookies
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="/privacy-policy"
                      className="text-reset"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
            <section className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h3 className="text-uppercase fw-bold mb-4 footer-title">
                Social
              </h3>
              <nav className="d-flex d-md-block justify-content-center justify-content-lg-between justify-content-sm-center ps-4 ps-md-0">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      rel="noreferrer"
                      href="https://github.com/mcgrathb89?tab=repositories"
                      target="_blank"
                      className="me-4 text-reset"
                      aria-label="Github"
                    >
                      <FontAwesomeIcon icon={faGithub} size="2x" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      rel="noreferrer"
                      href="https://www.linkedin.com/in/bobby-mcgrath/"
                      target="_blank"
                      className="me-4 text-reset"
                      aria-label="LinkedIn"
                    >
                      <FontAwesomeIcon icon={faLinkedin} size="2x" />
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
          </div>
        </div>
      </section>
      <section className="text-center p-4">
        © Copyright {year}. All Rights Reserved
      </section>
    </footer>
  );
};

export default Footer;
