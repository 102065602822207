import React from "react";
import Logo from "../Images/Logo.png";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();

  const usePathname = () => {
    return location.pathname;
  };

  return (
    <Navbar
      collapseOnSelect
      className="fixed-top p-4"
      expand="lg"
      bg="primary"
      variant="dark"
      id="myNav"
    >
      <Container>
        <a href="/" id="headerLink">
          <Image
            src={Logo}
            alt="Header Logo - Image of a person sitting at a keyboard with the words Bobby McGrath next to it."
            className="desktopAndTabletLogo"
          />
        </a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav defaultActiveKey={usePathname} as="ul">
            <li>
              <Nav.Link href="/">Home</Nav.Link>
            </li>
            <li>
              <Nav.Link href="/pre-built-websites">
                Pre-Built Websites For Sale
              </Nav.Link>
            </li>
            <li>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
