import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SEO from "../Components/SEO";
import SEOImage from "../Images/ImagePreview.webp";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const Cookies = () => {
  return (
    <>
      <Navbar />
      <Container className="cookies" as="main">
        <SEO
          title="Cookies | Bobby McGrath - Freelance Web Developer"
          description="Hi, I'm Bobby McGrath, a web developer based in Peterborough, UK. Here you can find my cookies policy and information."
          image={`https://www.bobby-mcgrath.co.uk${SEOImage}`}
          imageDescription="Screenshot of my portfolio website's homepage."
          imageWidth="1200"
          imageHeight="630"
          url="https://www.bobby-mcgrath.co.uk/cookies"
          noIndex={true}
        />
        <Row as="section">
          <Col>
            <h1>Cookie Policy for Bobby McGrath Freelance Web Developer</h1>
            <p>
              This is the Cookie Policy for Bobby McGrath Freelance Web
              Developer, accessible from{" "}
              <a href="/">https://www.bobby-mcgrath.co.uk/</a>
            </p>
            <p>
              <span>What Are Cookies</span>
            </p>
            <p>
              As is common practice with almost all professional websites this
              site uses cookies, which are tiny files that are downloaded to
              your computer, to improve your experience. This page describes
              what information they gather, how we use it and why we sometimes
              need to store these cookies. We will also share how you can
              prevent these cookies from being stored however this may downgrade
              or 'break' certain elements of the sites functionality.
            </p>
            <p>
              <span>How We Use Cookies</span>
            </p>
            <p>
              We use cookies for a variety of reasons detailed below.
              Unfortunately in most cases there are no industry standard options
              for disabling cookies without completely disabling the
              functionality and features they add to this site. It is
              recommended that you leave on all cookies if you are not sure
              whether you need them or not in case they are used to provide a
              service that you use.
            </p>
            <p>
              <span>Disabling Cookies</span>
            </p>
            <p>
              You can prevent the setting of cookies by adjusting the settings
              on your browser (see your browser Help for how to do this). Be
              aware that disabling cookies will affect the functionality of this
              and many other websites that you visit. Disabling cookies will
              usually result in also disabling certain functionality and
              features of the this site. Therefore it is recommended that you do
              not disable cookies.
            </p>
            <p>
              <span>The Cookies We Set</span>
            </p>
            <ul>
              <li>
                <p>Forms related cookies</p>
                <p>
                  When you submit data to through a form such as those found on
                  contact pages or comment forms cookies may be set to remember
                  your user details for future correspondence.
                </p>
              </li>
              <li>
                <p>Site preferences cookies</p>
                <p>
                  In order to provide you with a great experience on this site
                  we provide the functionality to set your preferences for how
                  this site runs when you use it. In order to remember your
                  preferences we need to set cookies so that this information
                  can be called whenever you interact with a page is affected by
                  your preferences.
                </p>
              </li>
            </ul>
            <p>
              <span>Third Party Cookies</span>
            </p>
            <p>
              In some special cases we also use cookies provided by trusted
              third parties. The following section details which third party
              cookies you might encounter through this site.
            </p>
            <ul>
              <li>
                <p>
                  This site uses Google Analytics which is one of the most
                  widespread and trusted analytics solution on the web for
                  helping us to understand how you use the site and ways that we
                  can improve your experience. These cookies may track things
                  such as how long you spend on the site and the pages that you
                  visit so we can continue to produce engaging content.
                </p>
                <p>
                  For more information on Google Analytics cookies, see the
                  official Google Analytics page.
                </p>
              </li>
              <li>
                <p>
                  From time to time we test new features and make subtle changes
                  to the way that the site is delivered. When we are still
                  testing new features these cookies may be used to ensure that
                  you receive a consistent experience whilst on the site whilst
                  ensuring we understand which optimisations our users
                  appreciate the most.
                </p>
              </li>
            </ul>
            <p>
              <span>More Information</span>
            </p>
            <p>
              Hopefully that has clarified things for you and as was previously
              mentioned if there is something that you aren't sure whether you
              need or not it's usually safer to leave cookies enabled in case it
              does interact with one of the features you use on our site.
            </p>
            <p>
              However if you are still looking for more information then you can
              contact us through one of our preferred contact methods:
            </p>
            <ul>
              <li>
                Email:&nbsp;
                <a href="mailto:mcgrathb89@gmail.com">mcgrathb89@gmail.com</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Cookies;
