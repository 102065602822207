import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Contact from "./Components/ContactSection";
import Cookies from "./Components/Cookies";
import PrivacyPolicy from "./Components/Privacy";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sass/App.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { PagesForSale } from "./Components/PagesForSale";
import { TheGiftWorldSiteInfo } from "./Components/TheGiftWorldSiteInfo";
import { BeckySmithVaSiteInfo } from "./Components/BeckySmithVaSiteInfo";
import { TeacherNewbieSiteInfo } from "./Components/TeacherNewbieSiteInfo";
import { JoshDarbySiteInfo } from "./Components/JoshDarbySiteInfo";

const App = (props) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/cookies" element={<Cookies />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/pre-built-websites" element={<PagesForSale />}></Route>
        <Route
          path="/becky-smith-virtual-assistant-showcase"
          element={<BeckySmithVaSiteInfo />}
        ></Route>
        <Route
          path="/chocolate-indulgence-website-showcase"
          element={<TheGiftWorldSiteInfo />}
        ></Route>
        <Route
          path="/teacher-newbie-website-showcase"
          element={<TeacherNewbieSiteInfo />}
        ></Route>
        <Route
          path="/josh-darby-website-showcase"
          element={<JoshDarbySiteInfo />}
        ></Route>
      </Routes>
    </Router>
  );
};

export default App;
