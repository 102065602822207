import React from "react";
import NavBar from "../Components/Navbar";
import { Hero } from "./Hero";
import { AboutSection } from "./AboutSection";
import { PortfolioSection } from "./PortfolioSection";
import { WhatIDoSection } from "./WhatIDoSection";
import { FrequentlyAskedQuestions } from "./FrequentlyAskedQuestions";
import { SEOSection } from "./SEOSection";
import Footer from "../Components/Footer";
import SEO from "../Components/SEO";
import SEOImage from "../Images/ImagePreview.webp";

const Home = () => {
  return (
    <div data-aos="fade-in">
      <SEO
        title="Homepage | Bobby McGrath - Bobby McGrath - Freelance Web Developer"
        description="Hi, I'm Bobby McGrath, a freelance web developer based in Peterborough, UK. I create responsive and user-friendly websites and web applications using modern technologies."
        image={`https://www.bobby-mcgrath.co.uk${SEOImage}`}
        imageDescription="Screenshot of my portfolio website's homepage."
        imageWidth="1200"
        imageHeight="630"
        url="https://www.bobby-mcgrath.co.uk/"
      />
      <NavBar />
      <Hero />
      <main>
        <AboutSection />
        <PortfolioSection />
        <WhatIDoSection />
        <FrequentlyAskedQuestions />
        <SEOSection />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
