import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import SEO from "../Components/SEO";
import SEOImage from "../Images/ContactUs.webp";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const ContactForm = () => {
  return (
    <>
      <Navbar />
      <Container fluid id="contact" as="main">
        <SEO
          title="Contact | Bobby McGrath - Freelance Web Developer"
          description="Hi, I'm Bobby McGrath, a web developer based in Peterborough, UK. Please feel free to contact me today for any enquires, questions or requests regarding my services."
          image={`https://www.bobby-mcgrath.co.uk${SEOImage}`}
          imageDescription="Screenshot of the contact us page."
          imageWidth="1200"
          imageHeight="630"
          url="https://www.bobby-mcgrath.co.uk/contact"
        />
        <Row className="contact">
          <Col as="section">
            <h1 className="text-center" data-aos="fade-right">
              Get in <span>Contact</span>
            </h1>
            <p className="text-center w-50" data-aos="fade-left">
              Interested in hiring me for your next project, purchasing a
              pre-made website, or perhaps you wish to know more about my skills
              and background? Whatever the case I am always happy to answer any
              questions you may have.
            </p>
          </Col>

          <Col as="section">
            <Form
              data-aos="fade-down"
              data-netlify="true"
              name="contact"
              method="POST"
              className="w-75 mainForm"
              netlify-honeypot="bot-field"
            >
              <p className="d-none">
                <label>
                  Don’t fill this out if you’re human:{" "}
                  <input name="bot-field" />
                </label>
              </p>

              <input type="hidden" name="form-name" value="contact" />

              <Form.Group className="mb-3">
                <Form.Label htmlFor="name">Full Name</Form.Label>
                <Form.Control type="text" id="name" name="name" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="email">Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  id="email"
                  name="email"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="message">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  id="message"
                  name="message"
                />
              </Form.Group>
              <Button type="submit" variant="third">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ContactForm;
