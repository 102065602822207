import React, { Component } from "react";

export default class Disclaimer extends Component {
  render() {
    return (
      <p
        className="alert alert-info disclaimer mx-auto mt-3 text-center"
        dangerouslySetInnerHTML={{ __html: this.props.text }}
      ></p>
    );
  }
}
