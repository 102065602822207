import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Arrow from "../Images/Arrow.png";

const ampersand = "&";

export const AboutSection = () => {
  return (
    <Container
      data-aos="fade-up"
      as="section"
      className="aboutSection"
      id="about"
    >
      <Row id="aboutMe">
        <Col>
          <h2>
            Skilled <span>Web Developer</span> <br />
            {ampersand} <span>Programmer</span>
          </h2>
        </Col>
        <Col>
          <h3 className="pb-3 about-me-text">
            Dedicated to Achieving the Best Results
          </h3>
          <p>
            As a web developer I pride myself on my work, I ensure that each
            site I build is responsive, follows SEO standards, adheres to best
            practices, is optimised for speed and functionality and perhaps most
            importantly offers a unique and enjoyable experience for each user.
            I am offering both lessons in how to learn web development and
            programming languages as well as building websites and apps for
            people too. Interested in hearing more about how I can help you? Get
            in touch today...
          </p>
          <a href="/contact">
            <Button variant="outline-primary" size="lg" className="mt-lg-5">
              Get a Quote
            </Button>
          </a>
          <Image
            size="lg"
            src={Arrow}
            alt="Arrow pointing to a button"
            className="arrowZoom"
          />
        </Col>
      </Row>
    </Container>
  );
};
